jQuery(document).ready(function($) {

	if (jQuery('.mixitup_filtered').length > 0){
		var mixer = mixitup(
			$('.mixitup_filtered'),
			{
				controls: {
					live: true,
					toggleLogic: 'and'
				}
			}
		);

		var filter_date = new Date().getTime();
		var collection = Array.from(
			document.querySelectorAll(".mix")
		);

		$(".event-controls #startdate").change(function(e) {
			var filter_str = $(e.target).val();
			filter_date = new Date(filter_str).getTime();
			var filtered = collection.filter(function(target) {
				var event_time = $(target)
					.find(".inner")
					.data("time");
				
				if (filter_str === "") return true;
				return parseInt(event_time) >= filter_date / 1000;
			});

			mixer.filter(filtered).then(function(state) {
				console.log("filtered by time");
			});
		});

		var filter_anlage = "";
		$(".event-controls #golfanlagen").change(function(e) {
			filter_anlage = $(e.target).val();
			var filtered = collection.filter(function(target) {
				var event_clubs = $(target)
					.find(".inner")
					.data("golfanlagen");
				if (filter_anlage === "") return true;
				if( !isNaN(event_clubs) ) event_clubs = event_clubs.toString();
				return event_clubs.includes(filter_anlage);
			});

			mixer.filter(filtered).then(function(state) {
				console.log("filtered by golfanlage");
			});
		});
	}



	this.handleChange = function(args){
		var that = this;
		
		// if ( $(".checkAll").is( ":checked" ) ){
		// 	$( ".control" ).prop( "checked", true );
		// 	// $(".control").addClass("mixitup-control-active");
		// } else{
		// 	$( ".control" ).prop( "checked", false );
		// }
	
	};

	var DM = new DynamicMap();
	DM.init();

	var FC = new FilterCollapse();
	FC.init();
});
