var FilterCollapse = function(){

	this.init = function(){
		
		var W = jQuery(window).width();
		var doSomething = function(){
			if(W <= 991){
				jQuery(".label-wrapper").addClass("close");
				jQuery(".label-wrapper").removeClass("open");
			}else{
				jQuery(".label-wrapper").removeClass("close");
				jQuery(".label-wrapper").addClass("open");
			}
		};

		jQuery(".label-wrapper").click(function(){
			jQuery(this).toggleClass("close open");
		});
		


		doSomething();

		jQuery(window).resize(function(){
			W = jQuery(window).width();
			doSomething();
		});


	};

};