function VMGooglemaps_initMap() {
	console.log("GMMaps", VMGM), VMGM.initMap();
}
var VMGooglemaps_Settings = function(a) {
		(this.settings = a),
			(this.defaultSettings = {
				loadGoogleMapsJSLib: !0,
				loadOverlappingMarkerSpiderfier: !0,
				OverlappingMarkerSpiderfierURL:
					"https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier/0.3.3/oms.min.js",
				loadMarkerClusterer: !0,
				MarkerClustererURL:
					"https://cdnjs.cloudflare.com/ajax/libs/js-marker-clusterer/1.0.0/markerclusterer_compiled.js",
				initCallbackFunction: "VMGooglemaps_initMap",
				initGeocode: "Deutschland",
				initZoom: 6,
				initMarkers: [],
				fitBoundsOnLoad: !0,
				useInfoWindows: !0,
				infoWindowOptions: {},
				useFilter: !1,
				filter: {
					filterElements: [
						{elemId: "", attr: "", triggerEvent: "change"}
					],
					centerAndZoomOnTriggerEvent: !0
				},
				googleMapSettings: {
					maxZoom: 17,
					scrollwheel: !1,
					styles: [
						{
							featureType: "administrative",
							elementType: "geometry",
							stylers: [{visibility: "off"}]
						},
						{
							featureType: "poi",
							elementType: "labels.icon",
							stylers: [{visibility: "off"}]
						},
						{
							featureType: "poi.park",
							stylers: [{visibility: "on"}]
						},
						{
							featureType: "road",
							elementType: "labels.icon",
							stylers: [{visibility: "off"}]
						},
						{featureType: "transit", stylers: [{visibility: "off"}]}
					]
				},
				markerOptions: {draggable: !1},
				markerCenterOnClick: !0,
				markerCenterOnClickZoom: 17,
				OverlappingMarkerSpiderfierOptions: {
					markersWontMove: !1,
					markersWontHide: !1,
					keepSpiderfied: !0,
					nearbyDistance: 20,
					circleSpiralSwitchover: 9,
					legWeight: 1.5,
					event: "mouseover"
				},
				MarkerClustererOptions: {
					gridSize: 25,
					maxZoom: 16,
					zoomOnClick: !0,
					averageCenter: !1,
					minimumClusterSize: 2,
					styles: [
						{
							url:
								"https://raw.githubusercontent.com/googlemaps/js-marker-clusterer/gh-pages/images/m1.png",
							height: 30,
							width: 30,
							textColor: "white",
							textSize: 12,
							backgroundPosition: "center"
						}
					]
				}
			}),
			(this.init = function() {
				var a = this;
				a.settings = a.getSettings();
			}),
			(this.getSettings = function() {
				var b = this,
					c = b.defaultSettings;
				if (null !== b.settings) {
					console.log("settings", b.settings),
						console.log("defaultSettings", b.defaultSettings);
					for (var d in b.settings) {
						var e = b.defaultSettings[d];
						(c[d] = e),
							"undefined" != typeof a[d] &&
								a.hasOwnProperty(d) &&
								(c[d] = a[d]);
					}
				}
				return console.log("finalSettings", c), c;
			}),
			(this.getSettingValue = function(a) {
				var b = this;
				return "undefined" != b.settings[a] ? b.settings[a] : null;
			});
	},
	VMGooglemaps_Loader = function() {
		(this.loadScript = function(a) {
			if ("undefined" != typeof a) {
				var b = document.createElement("script");
				(b.type = "text/javascript"),
					(b.src = a),
					document.body.appendChild(b);
			} else
				console.error(
					"VMGooglemaps_Loader - loadScript -> url is empty."
				);
		}),
			(this.loadAllDependencies = function(a, b, c) {
				var d = this;
				if (b.getSettingValue("loadGoogleMapsJSLib")) {
					var e = d.getGoogleMapsUrl(
						a,
						b.getSettingValue("initCallbackFunction")
					);
					d.loadScript(e);
				}
				var f = !1,
					g = setInterval(function() {
						if (d.checkGoogleMapsJSLibIsLoaded() && !f) {
							if (
								(clearInterval(g),
								(f = !0),
								b.getSettingValue("loadMarkerClusterer"))
							) {
								var a = b.getSettingValue("MarkerClustererURL");
								null != a
									? d.loadScript(a)
									: console.error(
											"You have to set the Setting: 'MarkerClustererURL' by your own. Download markerclustererplus from here: https://github.com/mahnunchik/markerclustererplus and reference it via Setting in VMGM"
									  );
							}
							b.getSettingValue(
								"loadOverlappingMarkerSpiderfier"
							) &&
								d.loadScript(
									b.getSettingValue(
										"OverlappingMarkerSpiderfierURL"
									)
								),
								"function" == typeof c && c();
						} else console.log("checkGoogleMapsJSLibIsLoaded not loaded yet!");
					}, 50);
			}),
			(this.getGoogleMapsUrl = function(a, b) {
				var c = !1;
				return (
					"undefined" != typeof a
						? (c =
								"https://maps.googleapis.com/maps/api/js?key=" +
								a +
								"&callback=" +
								b)
						: console.error(
								"VMGooglemaps_Loader - getGoogleMapsUrl -> key is empty."
						  ),
					c
				);
			}),
			(this.checkGoogleMapsJSLibIsLoaded = function() {
				return (
					"object" == typeof google && "object" == typeof google.maps
				);
			}),
			(this.checkOverlappingMarkerSpiderfierIsLoaded = function(a) {
				return !a || "undefined" != typeof OverlappingMarkerSpiderfier;
			});
	},
	VMGooglemaps_MarkerClusterer = function(a, b) {
		(this.map = a),
			(this.Settings = b),
			(this.MarkerClusterer = null),
			(this.init = function(a) {
				var b = this;
				b.Settings.getSettingValue("loadMarkerClusterer") &&
					(console.log(
						'that.Settings.getSettingValue("MarkerClustererOptions")',
						b.Settings.getSettingValue("MarkerClustererOptions")
					),
					(b.MarkerClusterer = new MarkerClusterer(
						b.map,
						a,
						b.Settings.getSettingValue("MarkerClustererOptions")
					))),
					google.maps.event.addListener(
						b.MarkerClusterer,
						"clusterclick",
						function(a) {
							var b = a.getMarkers()[0];
							console.log("Clusterclick", b);
						}
					);
			}),
			(this.handleFilterEventForMarkers = function(a) {
				var b = this;
				b.Settings.getSettingValue("loadMarkerClusterer") &&
					(b.MarkerClusterer.clearMarkers(),
					b.MarkerClusterer.addMarkers(a),
					a.forEach(function(a, c) {
						a.getVisible() || b.MarkerClusterer.removeMarker(a);
					}));
			});
	},
	VMGooglemaps_OverlappingMarkerSpiderfier_Marker = function(a, b, c) {
		(this.map = a),
			(this.oms = b),
			(this.Settings = c),
			(this.addInitMarkersToMarkers = function(a, b) {
				var c = this;
				a.length > 0
					? a.forEach(function(a) {
							c.oms.addMarker(a);
					  })
					: console.info("init Markers are empty");
			});
	},
	VMGooglemaps_OverlappingMarkerSpiderfier = function(a, b) {
		(this.map = a),
			(this.Settings = b),
			(this.oms = null),
			(this.OMS_Marker = null),
			(this.init = function(a) {
				var b = this;
				b.Settings.getSettingValue("loadOverlappingMarkerSpiderfier") &&
					((b.oms = new OverlappingMarkerSpiderfier(
						b.map,
						b.Settings.getSettingValue(
							"OverlappingMarkerSpiderfierOptions"
						)
					)),
					(b.OMS_Marker = new VMGooglemaps_OverlappingMarkerSpiderfier_Marker(
						b.map,
						b.oms,
						b.Settings
					)),
					console.log("initMarkers", a),
					b.OMS_Marker.addInitMarkersToMarkers(a));
			});
	},
	VMGooglemaps_GMap_Marker = function(a, b) {
		(this.map = a),
			(this.markers = []),
			(this.gmarkers = []),
			(this.Settings = b),
			(this.addInitMarkersToMarkers = function(a, b) {
				var c = this;
				if (
					(console.log("addInitMarkersToMarkers", a.length),
					a.length > 0)
				) {
					var d = null;
					a.forEach(function(a) {
						var b = new google.maps.LatLng(a.lat, a.lng),
							e = c.Settings.getSettingValue("markerOptions");
						(e.position = b),
							(e.map = c.map),
							(e.title = a.title),
							"undefined" != typeof a.icon &&
								"" != a.icon &&
								(e.icon.url = a.icon),
							(d = new google.maps.Marker(e)),
							c.gmarkers.push(d),
							c.markers.push(a);
					});
				} else console.info("init Markers are empty");
			}),
			(this.setAllMarkersOnMap = function() {
				for (var a = this, b = 0; b < a.markers.length; b++)
					a.markers[b].setMap(a.map);
			}),
			(this.initMarkerEvents = function(a, b) {
				var c = this;
				c.gmarkers.length > 0 &&
					c.gmarkers.forEach(function(d, e) {
						google.maps.event.addListener(d, "click", function() {
							var f = c.getMarkerBasedOnGMarkerByIndex(e);
							console.log("der marker", f);
							var g = f.infoWindowContent;
							console.log("content", g),
								a.openInfoWindow(d, g),
								c.centerAndZoomToMarker(d, b);
						});
					});
			}),
			(this.centerAndZoomToMarker = function(a, b) {
				var c = this;
				if (c.Settings.getSettingValue("markerCenterOnClick")) {
					console.log("OMS", b);
					var d = b.markersNearAnyOtherMarker(a);
					console.log("spiderified", d),
						0 === d.length &&
							c.map.setZoom(
								c.Settings.getSettingValue(
									"markerCenterOnClickZoom"
								)
							),
						c.map.setCenter(a.getPosition());
				}
			}),
			(this.getMarkerBasedOnGMarkerByIndex = function(a) {
				var b = this;
				return b.markers[a];
			}),
			(this.setVisibilityByAttributes = function(a, b, c) {
				var d = this;
				console.log("hier!");
				var e = 0;
				for (i = 0; i < d.gmarkers.length; i++) {
					var f = d.gmarkers[i],
						g = d.getMarkerBasedOnGMarkerByIndex(i);
					d.getCheckArray(g, a, b, function(a) {
						console.log("check", a),
							console.log("check false", a.indexOf(!1)),
							a.indexOf(!1) > -1
								? f.setVisible(!1)
								: f.setVisible(!0),
							e == d.gmarkers.length - 1 && c(),
							e++;
					});
				}
			}),
			(this.getCheckArray = function(a, b, c, d) {
				console.log("start getCheckArray");
				var e = [];
				b.forEach(function(f, g) {
					var h = c[g],
						i = a[f];
					console.log("val:" + h + " m_val:" + i),
						i == h || "" == h ? e.push(!0) : e.push(!1),
						g == b.length - 1 && d(e);
				});
			});
	},
	VMGooglemaps_GMap_InfoWindow = function(a, b) {
		(this.map = a),
			(this.Settings = b),
			(this.iw = null),
			(this.useInfoWindows = b.getSettingValue("useInfoWindows")),
			(this.options = b.getSettingValue("infoWindowOptions")),
			(this.init = function() {
				var a = this;
				a.useInfoWindows &&
					(a.iw = new google.maps.InfoWindow(a.options));
			}),
			(this.openInfoWindow = function(a, b) {
				var c = this;
				c.useInfoWindows && (c.iw.setContent(b), c.iw.open(c.map, a));
			}),
			(this.closeInfoWindow = function() {
				var a = this;
				a.useInfoWindows && a.iw.close();
			});
	},
	VMGooglemaps_GMap = function(a, b, c, d) {
		(this.map = null),
			(this.mc = null),
			(this.geocoder = null),
			(this.id = a),
			(this.init_geocode = b),
			(this.init_zoom = c),
			(this.markers = []),
			(this.init_markers = d),
			(this.infobox = null),
			(this.markerLabelShowZoomLevel = 6),
			(this.markerLabelClass = "markerLabel"),
			(this.markersData = null),
			(this.Settings = null),
			(this.Loader = new VMGooglemaps_Loader()),
			(this.GMapMarker = null),
			(this.GMapInfoWindow = null),
			(this.centerMapLatLng = null),
			(this.MarkerClusterer = null),
			(this.bounds = null),
			(this.init = function(a, b) {
				var c = this;
				(c.Settings = a),
					c.checkAllDependenciesLoaded(function() {
						console.log("googlemaps loaded"),
							(c.geocoder = new google.maps.Geocoder()),
							c.getMapsOptions(function(a) {
								(c.map = new google.maps.Map(
									document.getElementById(c.id),
									a
								)),
									(maxZoomService = new google.maps.MaxZoomService()),
									console.log(
										"maxZoomService",
										maxZoomService
									),
									c.map.addListener(
										"zoom_changed",
										function() {
											console.log(
												"ZOOOM",
												c.map.getZoom()
											);
										}
									),
									(c.GMapMarker = new VMGooglemaps_GMap_Marker(
										c.map,
										c.Settings
									)),
									c.GMapMarker.addInitMarkersToMarkers(
										c.init_markers
									),
									(c.OMS = new VMGooglemaps_OverlappingMarkerSpiderfier(
										c.map,
										c.Settings
									)),
									c.OMS.init(c.GMapMarker.gmarkers),
									(c.MarkerClusterer = new VMGooglemaps_MarkerClusterer(
										c.map,
										c.Settings
									)),
									c.MarkerClusterer.init(
										c.GMapMarker.gmarkers
									),
									(c.GMapInfoWindow = new VMGooglemaps_GMap_InfoWindow(
										c.map,
										c.Settings
									)),
									c.GMapInfoWindow.init(),
									c.GMapMarker.initMarkerEvents(
										c.GMapInfoWindow,
										c.OMS.oms
									),
									c.initFilter(),
									c.fitBounds();
							});
					});
			}),
			(this.initFilter = function() {
				var a = this;
				if (a.Settings.getSettingValue("useFilter")) {
					console.log("hier!!");
					var b = a.Settings.getSettingValue("filter"),
						c = b.filterElements;
					console.log("filterElements", c);
					c.forEach(function(b, d) {
						var e = document.getElementById(b.elemId);
						console.log("elem", e);
						var f = b.triggerEvent;
						console.log("triggerEvent", f),
							e.addEventListener(f, function() {
								a.andFilterMarker(c);
							}),
							d++,
							d == c.length && a.andFilterMarker(c);
					});
				}
			}),
			(this.andFilterMarker = function(a) {
				var b = this,
					c = [],
					d = [];
				a.forEach(function(e, f) {
					var g = document.getElementById(e.elemId),
						h = g.value,
						i = e.attr;
					c.push(i),
						d.push(h),
						f == a.length - 1 &&
							(console.log("attrs", c),
							console.log("attrs_values", d),
							b.GMapMarker.setVisibilityByAttributes(
								c,
								d,
								function() {
									var a = b.GMapMarker.gmarkers;
									b.MarkerClusterer.handleFilterEventForMarkers(
										a
									);
									var c = b.Settings.getSettingValue(
										"filter"
									);
									c.centerAndZoomOnTriggerEvent &&
										b.fitBounds(),
										b.GMapInfoWindow.closeInfoWindow();
								}
							));
				});
			}),
			(this.fitBounds = function() {
				var a = this,
					b = a.Settings.getSettingValue("fitBoundsOnLoad");
				if (b) {
					var c = a.GMapMarker.gmarkers;
					if (
						(a.Settings.getSettingValue("loadMarkerClusterer") ===
							!0 &&
							(c = a.MarkerClusterer.MarkerClusterer.getMarkers()),
						console.log("markers", c),
						console.log("markers.length", c.length),
						0 === c.length)
					) {
						var d = a.Settings.getSettingValue("initGeocode"),
							e = a.Settings.getSettingValue("initZoom");
						a.geocoder.geocode({address: d}, function(b, c) {
							if (c == google.maps.GeocoderStatus.OK) {
								var d = b[0].geometry.location;
								null === a.bounds &&
									(a.bounds = new google.maps.LatLngBounds()),
									console.log("initZoom", e),
									a.bounds.extend(d),
									a.map.setCenter(a.bounds.getCenter()),
									a.map.setZoom(e);
							} else console.log("Geocode was not successful for the following reason: " + c);
						});
					} else
						a.setBounds(c, function() {
							a.map.fitBounds(a.bounds),
								a.map.setCenter(a.bounds.getCenter()),
								a.map.setZoom(a.map.getZoom()),
								a.map.getZoom() >
									a.Settings.getSettingValue("maxZoom") &&
									a.map.setZoom(
										a.Settings.getSettingValue("maxZoom")
									);
						});
				}
			}),
			(this.setBounds = function(a, b) {
				var c = this;
				c.bounds = new google.maps.LatLngBounds();
				for (var d = !1, e = 0; e < a.length; e++) {
					var f = a[e];
					console.log("marker visibility", f.visible),
						f.visible &&
							((d = !0), c.bounds.extend(f.getPosition())),
						e == a.length - 1 &&
							(d === !1 && c.bounds.extend(c.centerMapLatLng),
							b());
				}
			}),
			(this.getMapsOptions = function(a) {
				var b = this;
				b.geocoder.geocode({address: b.init_geocode}, function(c, d) {
					if (d == google.maps.GeocoderStatus.OK) {
						var e = c[0].geometry.location;
						b.centerMapLatLng = e;
						var f = b.Settings.getSettingValue("googleMapSettings");
						(f.zoom = b.init_zoom),
							(f.center = e),
							console.log("googlemapssettings", f),
							a(f);
					} else console.log("Geocode was not successful for the following reason: " + d);
				});
			}),
			(this.checkAllDependenciesLoaded = function(a) {
				var b = this,
					b = this,
					c = !1,
					d = 0,
					e = setInterval(function() {
						!c && d < 200
							? (console.log(
									"checkGoogleMapsJSLibIsLoaded",
									b.Loader.checkGoogleMapsJSLibIsLoaded()
							  ),
							  console.log(
									"checkOverlappingMarkerSpiderfierIsLoaded",
									b.Loader.checkOverlappingMarkerSpiderfierIsLoaded(
										b.Settings.getSettingValue(
											"loadOverlappingMarkerSpiderfier"
										)
									)
							  ),
							  b.Loader.checkGoogleMapsJSLibIsLoaded() &&
							  b.Loader.checkOverlappingMarkerSpiderfierIsLoaded(
									b.Settings.getSettingValue(
										"loadOverlappingMarkerSpiderfier"
									)
							  )
									? (console.log("gmaps loaded!"),
									  a &&
											"function" == typeof a &&
											(clearInterval(e), (c = !0), a()))
									: console.log("not loaded yet!"),
							  d++)
							: (console.log(
									"google maps konnte nicht geladen werden -> abbruch"
							  ),
							  clearInterval(e));
					}, 50);
			});
	};
(VMGooglemaps = function() {
	(this.key = null),
		(this.id = null),
		(this.Settings = null),
		(this.Loader = new VMGooglemaps_Loader()),
		(this.GMap = null),
		(this.init = function(a, b, c, d) {
			var e = this;
			(e.id = b),
				(e.key = a),
				(e.Settings = new VMGooglemaps_Settings(c)),
				e.Settings.init(),
				e.Loader.loadAllDependencies(a, e.Settings, function() {
					console.log("dependencies loaded"),
						"function" == typeof d && d();
				});
			var f = e.Settings.getSettingValue("initGeocode"),
				g = e.Settings.getSettingValue("initZoom"),
				h = e.Settings.getSettingValue("initMarkers");
			e.GMap = new VMGooglemaps_GMap(b, f, g, h);
		}),
		(this.initMap = function() {
			var a = this;
			a.GMap.init(a.Settings);
		});
}),
	(VMGM = new VMGooglemaps());
