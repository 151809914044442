var DynamicMap = function(){

	this.init = function(){
		var that = this;
		if (jQuery('#map').length == 0) return false;

		var addresses = this.fetchAddresses();
		this.initFilterEventListener();
		this.initMap(addresses);
		
	};

	this.fetchAddresses = function(){
		var addresses = jQuery('.mix:visible >.inner').toArray().map(function (elem) {
			var data = jQuery(elem).data();
			data["visible"] = true;
			if (data["type"] == "golfanlage") {
				data["infoWindowContent"] = '<h4>'+data["title"]+'</h4><a href="'+data["link"]+'" title="mehr erfahren">mehr erfahren</a>';
			}
			if (data["type"] == "event") {
				var excerpt = jQuery(elem).find(".excerpt_wrapper")[0].outerHTML;
				var date = jQuery(elem).find(".datum")[0].outerHTML;
				data["infoWindowContent"] = '<h4 style="margin-bottom:5px;">'+data["title"]+'</h4>'+date+'<div style="max-width:320px;margin-bottom:10px;">'+excerpt+'</div><a href="'+data["link"]+'" title="mehr erfahren">mehr erfahren</a>';
			}
			return data;
		});
		return addresses;
	};

	this.initFilterEventListener = function(){
		var that = this;
		jQuery('.mixitup_filtered').on('mixEnd', function () {
			console.log("FILTERED!");
			var visibles = that.fetchAddresses();
			console.log(visibles);
			console.log(VMGM.GMap);

			//clear all markers
			for (var i = 0; i < VMGM.GMap.GMapMarker.gmarkers.length; i++) {
				VMGM.GMap.GMapMarker.gmarkers[i].setMap(null);
			}
			VMGM.GMap.GMapMarker.gmarkers = [];
			VMGM.GMap.GMapMarker.markers = [];
			//add new markers
			VMGM.GMap.GMapMarker.addInitMarkersToMarkers(visibles);
			//renew clusters
			VMGM.GMap.MarkerClusterer.handleFilterEventForMarkers( VMGM.GMap.GMapMarker.gmarkers );
			//focus new markers
			VMGM.GMap.fitBounds();

			
		});
	};

	this.initMap = function(addresses){
		var tmp = console.log;
		console.log = function(){};
		VMGM.init("AIzaSyACoID8uzmLbHHYKT0fEeUg-3vw1xcMDTM", "map", {
			loadGoogleMapsJSLib: true,
			loadOverlappingMarkerSpiderfier: true,
			initZoom: 9,
			initGeocode: "Deutschland",
			initMarkers: addresses,
			OverlappingMarkerSpiderfierOptions: {
				markersWontMove: false,
				markersWontHide: false,
				keepSpiderfied: true,
				nearbyDistance: 20,
				circleSpiralSwitchover: 9,
				legWeight: 1.5
			},
			maxZoom: 17,
			loadMarkerClusterer: true,
			MarkerClustererOptions: {
				gridSize: 25,
				// zoomOnClick: true,
				maxZoom: 16,
				// averageCenter: false,
				minimumClusterSize: 2,
				styles: [{
					url: "https://raw.githubusercontent.com/googlemaps/js-marker-clusterer/gh-pages/images/m1.png",
					height: 50,
					width: 50,
					textColor: "white",
					textSize: 12,
					backgroundPosition: "center",
				}]
			},
			useInfoWindows: true,
			useFilter: false,
			fitBoundsOnLoad: true,
			filter: {
				// filterElements: [{
				// 	elemId: "category",
				// 	attr: "kategorie",
				// 	triggerEvent: "change"
				// }, {
				// 	elemId: "plz",
				// 	attr: "plz",
				// 	triggerEvent: "change"
				// }, ],
			centerAndZoomOnTriggerEvent: true
	}
		}, function () {
			console.log = tmp;
			console.log('gmap erfolgreich geladen!');
		});
	};
};