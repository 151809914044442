function htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
}

function copyElements(callback){
	var wrapper = document.querySelector('.turniere-in-mv-liste');
	var tabs = document.querySelector('.turniere-in-mv-liste .ui-tabs-nav');
	
	var idsToCopy = ["titeltraeger-1", "club-statistik-1"];

	for (var i = 0; i < idsToCopy.length; i++) {
		var element = document.getElementById(idsToCopy[i]);
		if(element === null) return false;
		var tabsToCopy = element.parentNode;
		
		var tabsToCopyContent = tabsToCopy.outerHTML;
		tabsToCopyContent = tabsToCopyContent.replace(new RegExp("ui-id-(.)","g"), 'ui-id-$1'+i);
		var titleElement = tabsToCopy.parentNode.querySelector('.title');
		var title = titleElement.innerHTML;
		var custom_id = "ui-id-custom-"+i;
		var anchor = "tab-extend-"+i;
		
		var new_tab = htmlToElement('<li class="ui-state-default ui-corner-top" role="tab" tabindex="-1" aria-controls="'+anchor+'" aria-labelledby="ui-id-2" aria-selected="false" aria-expanded="false"><a href="#'+anchor+'" class="ui-tabs-anchor" role="presentation" tabindex="-1" id="'+custom_id+'">'+title+'</a></li>');
		
		var new_tabcontent = htmlToElement('<div id="'+anchor+'" aria-labelledby="'+custom_id+'" class="ui-tabs-panel ui-widget-content ui-corner-bottom" role="tabpanel" aria-hidden="true" style="display: none;">'+tabsToCopyContent+'</div>');
		
		tabs.append(new_tab);
		wrapper.append(new_tabcontent);
		
		tabsToCopy.parentNode.remove();
		jQuery('#tab-extend-'+i+' .jq-tabs').tabs();
	}
	callback();
}

copyElements(function(){
	jQuery('.jq-tabs').tabs("refresh");
});